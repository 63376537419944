<template>
    <div>
        <v-toolbar flat>
            <v-icon left>mdi-clipboard-check-outline</v-icon>
            <v-toolbar-title>TAREAS GENERAL</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-autocomplete :items="usuarios" item-text="nombres" item-value="user_login_id"></v-autocomplete>
            <v-btn @click="abrirTareaMotivoDialog(null)" color="blue" small dark><v-icon>mdi-plus</v-icon> Crear</v-btn>
            <v-text-field class="mx-4 pt-4 mt-4" v-model="search" label="Buscar" ></v-text-field>
            
        </v-toolbar>
        <v-data-table :headers="headers" :items-per-page="itemsPerPage" 
            :items="motivos" class="elevation-1" small
            item-key="id" hide-default-footer
            :search="search"
            >
            <template v-slot:item.opciones="{ item }">
                <v-btn small text @click="borrarMotivo(item.motivo_id)" color="blue" small dark><v-icon small
                        color="red">mdi-delete</v-icon></v-btn>
            </template>
            <template v-slot:item.motivo_id="{ item }">
                <v-btn text small @click="abrirTareaMotivoDialog(item.motivo_id)">
                    {{ item.motivo_id }}
                </v-btn>
            </template>
            <template v-slot:footer>
                <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
                    total-visible="10"></v-pagination>
            </template>
        </v-data-table>

        <!-- DIALOGO MOTTIVO -->
        <v-dialog v-model="dialogoMotivoTarea" persistent width="700">
            <v-form ref="formMotivo">
                <v-card>

                    <v-toolbar dark color="blue" height="40" flat>
                        <v-icon left>mdi-target</v-icon>
                        Motivo
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dialogoMotivoTarea = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-row class="mt-1 mx-2 pt-0 mt-0">
                        <v-col md="12" class="py-0 my-0">
                            <v-text-field v-if="crearMotivo == false"  :rules="requiredRule" v-model="datosMotivo.motivo_id"
                                label="Nombre"></v-text-field>
                        </v-col>

                        <v-col md="12" class="py-0 my-0">
                            
                           
                            <v-autocomplete
                                :rules="requiredRule"
                                :items="categorias"
                                item-value="motivo_cat_id"
                                item-text="descripcion"
                                label="Categoria"
                                v-model="datosMotivo.motivo_cat_id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-text-field :rules="requiredRule" v-model="datosMotivo.activo" label="Activo"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-toolbar>
                        <v-btn color="primary" small @click="guardarMotivo">
                            <v-icon>mdi-content-save</v-icon>Guardar
                        </v-btn>
                        <v-divider vertical class="px-2" inset></v-divider>
                        <v-btn text small @click="dialogoMotivoTarea = false">
                            <v-icon>mdi-content-cancel</v-icon>Cancelar
                        </v-btn>
                    </v-toolbar>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from 'vue';

export default {
    name: "TareasMotivos",
    data: () => ({
        show: false,

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        search: "",
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 0,
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "motivo_id" },
            { text: "Motivo", align: "start", value: "descripcion" },
            { text: "Activo", align: "start", value: "activo" },
            { text: "Categoria", align: "start", value: "motivo_cat_id" },
            { text: "Acción", align: "start", value: "accion_nombre" },
        ],
        motivos: [],
        
        dialogoMotivoTarea: false,
        datosMotivo: {
            motivo_id: "",
            activo: "",
            motivo_cat_id: "",
            descripcion: "",
            accion_nombre: ""
        },
        catalogo: [],
        categorias: [],
        acciones:[],
        crearMotivo: false,
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
            "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),

        cargarDatos() {
            this.setLoadingTable(true);

            this.setUrl("tarea-motivo");

            this.requestApi({
                method: "GET",
                data: {
                    page: this.currentPage,
                    page_size: this.itemsPerPage,
                    //paginator: true,
                    //source_type_id: "LEAD_SOURCE",
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.motivos = res.data._embedded.tarea_motivo;
                    this.pageCount = res.data.page_count;
                    this.currentPage = res.data.page;
                    this.totalItems = res.data.total_items;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },
        abrirTareaMotivoDialog(Id) {
            this.dialogoMotivoTarea  = true;
            this.cargarDatosMotivo(Id);
            this.datosMotivo = {
                motivo_id: "",
                motivo_cat_id: "",
                descripcion: "",
            };
            this.crearMotivo = true;
            if (Id != null) {
                this.crearMotivo = false;                                
            }
        },

        cargarDatosMotivo(id) {
            this.setLoadingTable(true);
            this.setUrl("tarea-motivo");
            this.requestApi({
                method: "POST",
                data: {accion:"datosTareaMotivo","motivo_id" : id},
            })
                .then((res) => {
                    
                    if(res.data.detail)
                    this.datosMotivo = res.data.detail;
                    this.categorias = res.data.detail.categorias
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        guardarMotivo() {
            if (!this.$refs.formMotivo.validate()) {
                return false
            }
            this.setLoadingTable(true);
            this.setUrl("tarea-motivo");
            this.requestApi({
                method: "POST",
                data: {
                    accion: this.crearMotivo == true ? "crearMotivo" : "modificarMotivo",
                    datosMotivo: this.datosMotivo,
                },
            }).then((res) => {
                    //console.log(res.data);
                    this.cargarDatos();
                    this.dialogoMotivoTarea = false;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });

        },
        borrarMotivo(Id) {

            Vue.swal({
                html: "Está seguro de eliminar este motivo  ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                this.setLoadingTable(true);
                this.setUrl("tarea-motivo");
                this.requestApi({
                    method: "POST",
                    data: {
                        accion: "borrarMotivo",
                        motivo_id: Id, 
                    },
                })
                    .then((res) => {
                        //console.log(res.data);
                        this.cargarDatos();
                        this.dialogoMotivoTarea = false;
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
            });
        },
        handlePageChange(value) {
            this.cargarDatos();
        },
    },
    mounted() {
        this.cargarDatos();
        this.setTitleToolbar("TAREAS GENERAL");
    },
};
</script>
